import React from 'react'
import Print from '../components/UI/Print';





function PrintPage() {
  return (
    <div>
      <Print/>
    </div>
  )
};


export default PrintPage